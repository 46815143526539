
.root {
  padding: 16px;
  box-shadow: var(--shadow) !important;
}

.root > h3 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.root > h3::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 32px;
  background-color: black;
  margin-right: 16px;
  border-radius: 6px;
}

.content {
  position: relative;
  height: 92%;
  padding-bottom: 16px;
}
