body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: unset;
  font-size: 0.9em;
  padding: 2px;
  background-color: rgb(228, 228, 228);
  border-radius: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

* {
  box-sizing: border-box;
}

:root {
  --shadow: 5px 6px 40px -5px rgba(0, 0, 0, 0.05);
  --hardshadow: 5px 6px 40px -5px rgba(0, 0, 0, 0.2);
  --text-grey: rgb(90, 90, 90);
  --page-padding: 32px;
}

@media screen and (max-width: 960px) {
  :root {
    --page-padding: 8px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.fade {
  animation-name: fadein;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}
