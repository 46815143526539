.root {
  display: flex;
  flex-direction: row;
}

.sider {
  position: fixed;
  width: 250px;
  z-index: 1;
}

.content {
  width: 100%;
  position: relative;
}

.contentdrawer {
  padding-left: 250px;
}

.drawerbutton {
  position: absolute !important;
  top: 6px;
  left: 8px;
  z-index: 1;
}
